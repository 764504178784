/* global variables */
:root {
    --white: rgb(230, 230, 255);
    --grey: rgb(169, 169, 189);
    --backgroundBlue: #02162c;
    --offBlue: #032141;
}
/* affects the color behind the webpage (for overscroll) */
html {
    background-color: var(--backgroundBlue);
}

/* -------------------- ENTIRE PAGE -------------------- */
.Page3 {
    width: 100%;
    overflow: hidden;
}
.page3Contain {
    opacity: 0;
}
.loaded {
    transition: opacity ease-in-out 2s;
    opacity: 1;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--white);
}
@media screen and (min-width: 1024px) {
    .page3Contain {
        width: 80%;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 12vh;
    }
    
    .page3text {
        display: flex;
        flex-direction: row;
    }
    
    /* ---------- LEFT SIDE ---------- */
    .leftSide {
        margin: 0;
        width: 30%;
        height: 100%;
        position: fixed;
    } 
}
@media screen and (max-width: 1023px) {
    .page3Contain {
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 2vh;
    }
    
    .page3text {
        display: flex;
        flex-direction: column;
    }
}

/* ---------- LEFT SIDE ---------- */
.leftSide {
    margin: 0;
}
@media screen and (min-width: 1024px) {
    .leftSide {
        width: 30%;
        height: 100%;
        position: fixed;
    }  
}

@media screen and (max-width: 1023px) {
    .leftSide {
        position: relative;
        height: auto;
        width: 90%;
        margin-left: 5%;
        margin-top: 8vh;
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: left;
    } 
}

/* -------------------- NAME SECTION -------------------- */
.nameTitle {
    margin: 0;
    margin-bottom: 10px;
    font-weight: 800;
    cursor: default;
}
.nameText {
    margin: 0;
    margin-bottom: 10px;
    font-weight: 600;
    cursor: default;
}
.nameBody {
    margin: 0;
    font-weight: 500;
    margin-bottom: 5px;
    color: var(--grey);
    cursor: default;
}
.colorChange {
    transition: ease-in-out 0.3s;
    margin: 0;
    font-weight: 500;
    color: var(--grey);
    cursor: default;
    font-size: 15px;
}
/* word color change on hover */
.colorChange:hover {
    transition: ease-in-out 0.3s;
    color: var(--white);
}

@media screen and (min-width: 2400px) {
    .nameTitle {
        font-size: 90px;
    }
    .nameText {
        font-size: 40px;
    }
    .nameBody {
        font-size: 30px;
    }
    .colorChange {
        font-size: 30px;
    }
}
@media screen and (min-width: 1024px) and (max-width: 2400px) {
    .nameTitle {
        font-size: 45px;
    }
    .nameText {
        font-size: 20px;
    }
    .nameBody {
        font-size: 15px;
    }
}
@media screen and (max-width: 1023px) {
    .nameTitle {
        margin-bottom: 20px;
        font-size: 40px;
        height: 30px;
    }
    .nameText {
        font-size: 20px;
    }
    .nameBody {
        margin: 0;
        font-size: 16px;
    }
}

/* -------------------- NAVBAR SECTION -------------------- */
.navbar {
    margin: 0;
}
.navButton {
    position: relative;
    transition: ease-in-out .2s;
    width: fit-content;
    margin: 0;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    border: 0;
    color: var(--grey);
}
.navButton b {
    position: fixed;
    margin: 0;
    transition: ease-in-out .2s;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
}
.navButton b:hover {
    transition: ease-in-out .2s;
    font-weight: 700;
}
.navButton:hover {
    position: relative;
    transition: ease-in-out .2s;
    width: auto;
    margin: 0;
    padding-bottom: 5px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    border: 0;
    color: var(--white);
    cursor: pointer;
}
.navButtonLine {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-left: 10px;
    width: 1px;
    background-color: var(--grey);
    transition: width 0.3s ease-in-out;
    cursor: default;
}
@media screen and (min-width: 2400px) {
    .navbar {
        margin-top: 20%;
    }
    
    .navButton {
        margin-bottom: 70px;
    }
    
    .navButton b {
        font-size: 40px;
    }
    
    .navButtonLine {
      top: 60px;
      height: 60px;
    }
}
@media screen and (min-width: 1024px) and (max-width: 2400px){
    .navbar {
        margin-top: 80px;
    }
    
    .navButton {
        margin-bottom: 15px;
    }
    
    .navButton b {
        font-size: 15px;
    }
    
    .navButtonLine {
      top: 15px;
      height: 30px;
    }
}
@media screen and (max-width: 1023px) {
    .navbar {
        display: flex;
        justify-content: center;
        margin-top: 4vh;
        margin-bottom: 4vh;
    }

    .navButton {
        display: none;
    }
}

/* -------------------- SOCIAL LINKS SECTION -------------------- */
.logo {
    transition: ease-in-out .3s;
    margin-top: 5px;
    opacity: 70%;
    border-radius: 12.5px;
}
.logo:hover {
    transition: ease-in-out .3s;
    margin-top: 2px;
    margin-bottom: 3px;
    border-radius: 12.5px;
    opacity: 100%;
    cursor: pointer;
}
.socialLogo {
    display: flex;
    flex-direction: row;
}
.resumeButton {
    width: 75px;
    color: var(--backgroundBlue);
    background-color: white;
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    height: 30px;
    transition: ease-in-out .3s;
    margin-top: 5px;
    margin-right: 30px;
    border-radius: 12.5px;
    opacity: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
}
.resumeButton:hover {
    transition: ease-in-out .3s;
    margin-top: 2px;
    margin-right: 15px;
    margin-bottom: 3px;
    border-radius: 12.5px;
    opacity: 100%;
    cursor: pointer;
}

@media screen and (min-width: 2400px) {
    .logo {
        width: 60px;
        height: 60px;
        margin-right: 30px;
    }
    
    /* social logos container */
    .socialLogo {
        position: fixed;
        bottom: 12vh;
    }
}
@media screen and (min-width: 1024px) and (max-width: 2400px) {
    .logo {
        width: 30px;
        height: 30px;
        margin-right: 15px;
    }
    
    .logo:hover {
        margin-right: 15px;
    }
    
    /* social logos container */
    .socialLogo {
        position: fixed;
        bottom: 12vh;
    }
}
@media screen and (max-width: 1023px) {
    .logo {
        width: 30px;
        height: 30px;
        margin-right: 15px;
    }
    
    .logo:hover {
        margin-right: 15px;
    }

    .resumeButton {
        margin-right: 0;
    }
}

/* -------------------- RIGHT SIDE OF PAGE -------------------- */
.rightSide {
    margin: 0;
    position: relative;
}
@media screen and (min-width: 1024px) {
    .rightSide {
        width: 60%;
        left: 40%;
    }
}
@media screen and (max-width: 1023px) {
    .rightSide {
        width: 90%;
        left: 5%;
    }
}

/* -------------------- ABOUT ME -------------------- */
.aboutMe {
    margin: 0;
    margin-bottom: 10vh;
}
.aboutMeText {
    margin: 0;
    font-weight: 500;
    color: var(--grey);
}
.aboutMeTextColor {
    margin: 0;
    font-weight: 500;
    color: var(--white);
}
@media screen and (min-width: 2400px) {
    .aboutMeText {
        font-size: 40px;
    }
    
    /* coloring emphasised words */
    .aboutMeTextColor {
        font-size: 40px;
    } 
}
@media screen and (min-width: 1024px) and (max-width: 2400px) {
    .aboutMeText {
        font-size: 18px;
    }
    
    /* coloring emphasised words */
    .aboutMeTextColor {
        font-size: 18px;
    }
}
@media screen and (max-width: 1023px) {
    .aboutMeText {
        font-size: 16px;
    }
    
    /* coloring emphasised words */
    .aboutMeTextColor {
        font-size: 16px;
    }
}

/* -------------------- EXPERIANCE -------------------- */
.experiance {
    margin: 0;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
}
/* each experiance card */
.experianceCard {
    transition: ease-in-out .2s;
    margin: 0;
    margin-bottom: 2vh;
    padding: 15px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    border-top-style: solid;
    border-top-width: 1px;
    border-color: rgba(255, 255, 255, 0); 
}
.experianceCard:hover {
    transition: ease-in-out .2s;
    background-color: rgba(255, 255, 255, 0.05);
    border-top-style: solid;
    border-top-width: 1px;
    border-color: rgba(255, 255, 255, 0.1); 
}
/* section subheading container */
.subheadTitleContain {
    margin: 0;
    margin-bottom: 5vh;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.subheadTitle {
    margin: 0;
}
.subheadLine {
    width: 100%;
    height: 1px;
    background-color: var(--grey);
    margin-left: 5%;
    margin-right: 5%;
    align-self: center;
}
.timeline {
    width: 22%;
    font-weight: 300;
    color: var(--grey);
    margin: 0;
}
/* experiance text */
.textContain {
    width: 78%;
    color: var(--white);
}
.textContain h2 {
    margin: 0;
    font-weight: 500;
}
.textContain h3 {
    margin: 0;
    margin-bottom: 10px;
    font-weight: 400;
    color: var(--grey);
}
.textContain p {
    margin: 0;
    font-weight: 400;
    color: var(--grey);
}
@media screen and (min-width: 2400px) {
    /* section subheadings */
    .subheadTitle {
        font-size: 50px;
    }
    
    /* dates of work */
    .timeline {
        font-size: 20px;
        padding-top: 15px;
    }
    
    .textContain h2 {
        font-size: 40px;
    }
    
    .textContain h3 {
        font-size: 30px;
    }
    
    .textContain p {
        font-size: 30px;
    }
}
@media screen and (min-width: 1024px) and (max-width: 2400px) {
    /* section subheadings */
    .subheadTitle {
        font-size: 30px;
    }
    
    /* dates of work */
    .timeline {
        font-size: 12px;
        padding-top: 10px;
    }
    
    .textContain h2 {
        font-size: 25px;
    }
    
    .textContain h3 {
        font-size: 20px;
    }
    
    .textContain p {
        font-size: 18px;
    }
}
@media screen and (max-width: 1023px) {            
    /* dates of work */
    .timeline {
        width: 20%;
        font-size: 12px;
        margin-right: 5px;
        padding-top: 4px;
    }
    
    .textContain h2 {
        font-size: 20px;
    }
    
    .textContain h3 {
        font-size: 18px;
    }
    
    .textContain p {
        font-size: 16px;
    }
}

/* -------------------- PROJECTS -------------------- */
/* container for project tab */
.projectContainer {
    width: 100%;
    margin: 0; 
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
}
.projectCard {
    margin-bottom: 2vh;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
}
.leftSidePic {
    display: flex;
    width: 50%;
    height: auto;
    z-index: 1;
    justify-content: center;
    align-items: center;
}
.rightSidePic {
    display: flex;
    width: 50%;
    height: auto;
    z-index: 1;
    justify-content: center;
    align-items: center;
}
/* project picture */
.projectPic {
    transition: ease-in-out .3s;
    width: 100%;
    border-radius: 10px;
    opacity: 70%;
}
.projectPic:hover {
    transition: ease-in-out .3s;
    border-radius: 10px;
    opacity: 100%;
    cursor: pointer;
}
/* container for all project text */
.rightSideText {
    direction: rtl; /* this is to overflow to the left */
    text-align: center;
    z-index: 2;
    width: 50%;
    display: flex;
    flex-direction: column;
    cursor: default;
    justify-content: center;
}
.leftSideText {
    direction: ltr; 
    text-align: center;
    width: 50%;
    display: flex;
    flex-direction: column;
    z-index: 2;
    cursor: default;
    justify-content: center;
}
.projectTypeL {
    margin: 0;
    align-self: center;
    font-size: 15px;
    font-weight: 300;
    color: var(--grey);
}
.projectTitle {
    margin: 0;
    font-size: 50px;
    font-weight: 500;
    color: var(--white);
    margin-bottom: 5px;
    text-align: center;
}
.projectTitleL {
    margin: 0;
    font-size: 50px;
    font-weight: 500;
    color: var(--white);
    margin-bottom: 5px;
    text-align: center;

}
.projectText {
    margin: 0;
    width: 100%;
    height: auto;
    border-radius: 10px;
    background-color: var(--offBlue);
    padding: 10px;
}
.projectTextL {
    margin: 0;
    width: 100%;
    height: auto;
    border-radius: 10px;
    background-color: var(--offBlue);
    padding: 10px;
}
.projectText b {
    font-size: 30px;
    font-weight: 300;
}
.projectText a {
    transition: ease-in-out .2s;
    text-decoration: none;
    color: var(--white);
}
.projectText a:hover {
    transition: ease-in-out .2s;
    color: var(--grey);
}
.projectTextL b {
    font-size: 30px;
    font-weight: 300;
}
.codingTypes {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.codingTypes b {
    transition: ease-in-out .3s;
    font-size: 30px;
    font-weight: 300;
    margin-right: 30px;
    text-align: center;
    color: var(--grey);
}
.codingTypes b:hover {
    transition: ease-in-out .3s;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    color: var(--white);
}
.socialLinkContain {
    margin: 0;
    text-align: right;
    display: flex;
    flex-direction: row;
}
.socialLink {
    transition: ease-in-out .3s;
    width: 30px;
    height: 30px;
    margin-top: 5px;
    margin-right: 30px;
    border-radius: 12.5px;
    opacity: 70%;
}
.socialLink:hover {
    transition: ease-in-out .3s;
    margin-top: 5px;
    border-radius: 12.5px;
    cursor: pointer;
    opacity: 100%;
}
@media screen and (min-width: 1024px) and (max-width: 2400px) {
    .projectTitle {
        font-size: 25px;
    }
    .projectTitleL {
        font-size: 25px;

    }
    .projectText b, .projectText a {
        font-size: 15px;
    }
    .projectTextL b {
        font-size: 15px;
    }
    .codingTypes b {
        font-size: 15px;
        margin-right: 10px;
    }
    .codingTypes b:hover {
        font-size: 15px;
        margin-right: 10px;
    }
    .socialLink {
        width: 20px;
        height: 20px;
        margin-right: 15px;
    }
}
@media screen and (min-width: 800px) and (max-width: 1023px) {
    .projectCard {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .leftSidePic {
        height: 50%;
    }
    .rightSidePic {
        height: 50%;
    }
    .projectTitle {
        font-size: 25px;
    }
    .projectTitleL {
        font-size: 25px;

    }
    .projectText b, .projectText a {
        font-size: 15px;
    }
    .projectTextL b {
        font-size: 15px;
    }
    .codingTypes b {
        font-size: 15px;
        margin-right: 10px;
    }
    .codingTypes b:hover {
        font-size: 15px;
        margin-right: 10px;
    }
    .socialLink {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
    .socialLink:hover {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    } 
}
@media screen and (max-width: 799px) {
    .projectCard {
        justify-content: center;
    }
    .leftSidePic {
        display: none;
    }
    .rightSidePic {
        display: none;
    }
    .projectPic {
        display: none;
    }
    .rightSideText {
        width: 100%;
    }
    .leftSideText {
        width: 100%;
    }
    .projectTitle {
        font-size: 25px;
    }
    .projectTitleL {
        font-size: 25px;
    }
    .projectText {
        width: 95%;
    }
    .projectTextL {
        width: 95%;
    }
    .projectText b, .projectText a {
        font-size: 15px;
    }
    .projectTextL b {
        font-size: 15px;
    }
    .codingTypes b {
        font-size: 15px;
        margin-right: 10px;
    }
    .codingTypes b:hover {
        font-size: 15px;
        margin-right: 10px;
    }
    .socialLink {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
    .socialLink:hover {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    } 
}

/* -------------------- LANGUAGES -------------------- */
.languagesContainer {
    margin: 0;
}
.languageLogoContainer {
    margin: 0;
    width: 100%;
    display: grid;
    grid-template-rows: repeat(2, 225px);
    grid-template-columns: repeat(4, 25%);
    justify-items: center;
}
.languageLogo {
    z-index: 2;
    margin: 0;
    width: 200px;
    height: 200px;
}
.gitLinkContainer {
    display: flex;
    margin: 0;
    width: 100%;
    margin-bottom: 5vh;
    justify-content: center;
    align-items: center;
}
.gitLink {
    display: flex;
    transition: ease-in-out .2s;
    border-radius: 5px;
    padding: 5px;
    margin-top: 25px;
    width: fit-content;
    border-top-style: solid;
    border-top-width: 1px;
    border-color: rgba(255, 255, 255, 0); 
    color: var(--white);
    text-decoration: none;
    font-size: 30px;
}
.gitLink:hover {
    transition: ease-in-out .2s;
    background-color: rgba(255, 255, 255, 0.05);
    border-top-style: solid;
    border-top-width: 1px;
    border-color: rgba(255, 255, 255, 0.1); 
    cursor: pointer;
}

@media screen and (min-width: 1024px) and (max-width: 2400px) {
    .languageLogoContainer {
        grid-template-rows: repeat(2, 125px);
    }
    .languageLogo {
        width: 100px;
        height: 100px;
    }
    .gitLink {
        font-size: 15px;
    }
}
@media screen and (min-width: 546px) and (max-width: 1023px) {
    .languageLogoContainer {
        grid-template-rows: repeat(1, 125px);
    } 
    .languageLogo {
        width: 100px;
        height: auto;
        padding: 25px;
    }

    .gitLink {
        font-size: 16px;
    }
}
@media screen and (max-width: 545px){
    .languageLogoContainer {
        grid-template-rows: repeat(1, 100px);
    } 
    .languageLogo {
        width: 70px;
        height: auto;
        padding: 15px;
    }

    .gitLink {
        font-size: 16px;
    } 
}

/* -------------------- CONTACT ME ------------------- */
.contactContainer {
    width: 100%;
    margin: 0;
    margin-bottom: 5vh;
}
.contactHeaderText {
    width: 100%;
    margin: 0;
    display: flex;
    text-align: center;
    justify-content: center;
}
.contactHeaderText a {
    transition: ease-in-out .3s;
    text-decoration: none;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: var(--grey);
}
.contactHeaderText a:hover {
    transition: ease-in-out .3s;
    color: var(--white);
    font-weight: 900;
}